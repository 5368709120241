import Vue from "vue";
import {DateTime} from "luxon";
import {SportEventSummary} from "@/models";

export function installFilters() {
  Vue.filter("eventTime", (event: SportEventSummary) => {
    const dateFormat = 'yyyy-MM-dd';
    const now = DateTime.local().setZone("America/Chicago");
    const startDate = DateTime.fromISO(event.startDateTime, {zone: "America/Chicago"});
    const final = event.awayTeamScore && event.homeTeamScore;
    let dateText = "";
    const today = now.toFormat(dateFormat);
    const startDateString = startDate.toFormat(dateFormat);
    if (today === startDateString) {
      dateText = "Today";
    } else if (final || truncateToMidnight(now).plus({day : 6}) < truncateToMidnight(startDate)) {
      dateText = startDate.toFormat("M/d");
    } else {
      dateText = startDate.toFormat("EEE");
    }
    const timeText = final ? "FINAL" : startDate.toFormat("h:mm a");
    return `${dateText} — ${timeText}`;
  });

}

function truncateToMidnight(date: DateTime) {
  return date.set({hour: 0, minute: 0, second: 0});
}
