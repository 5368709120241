import Vue from 'vue';
import LoadingMixin from "@/mixins/LoadingMixin";
import {SportEventSummary} from "@/models";
import {getBannerEvents} from "@/services/SportsScheduleService";
import {DateTime} from "luxon";
import SportBannerEvent from "@/SportBannerEvent.vue";

export default Vue.extend({
    name: 'SportsBannerApp',
    mixins: [LoadingMixin],
    components: {SportBannerEvent},
    data() {
        return {
            events: [] as unknown as SportEventSummary[],
            scrolling: false,
            canScroll: true,
            showFillerImage: false
        }
    },
    computed: {
        earliestEventUrl(): string {
            const now = DateTime.local();
            const earliestEvent = this.events.filter((event) => DateTime.fromISO(event.endDateTime) >= now)[0];
            if (earliestEvent) {
                return `/schedule/#/?showEventId=${earliestEvent.id}&openEventDetails=false`;
            } else {
                return "/schedule/#/";
            }
        }
    },
    async mounted() {
        this.$nextTick(function () {
            this.checkForScroll();
            window.addEventListener('resize', this.checkForScroll);
        })
    },
    async created() {
        await (this as any).execute(async () => {
            const results = await getBannerEvents();
            const now = DateTime.local();
            this.events = results.filter(event => {
                const endDate = DateTime.fromISO(event.endDateTime);
                if (endDate < now) {
                    return event.awayTeamScore && event.homeTeamScore;
                }
                return true;
            });
        }, 'Failed to fetch banner events');
        this.checkForScroll();
    },
    methods: {
        checkForScroll() {
            const scrollPane = document.getElementById('sport-banner__events');
            if (scrollPane) {
                const scrollPaneWidth = scrollPane.getBoundingClientRect().width;
                const totalNeededWidth = Array.from(scrollPane.getElementsByClassName("sport-banner-event"))
                    .reduce((accumulator, element) => accumulator + element.getBoundingClientRect().width, 0);
                this.showFillerImage = scrollPaneWidth - 200 > totalNeededWidth;
                this.canScroll = scrollPaneWidth < totalNeededWidth;
            }
        },
        scrollLeft() {
            if (this.scrolling || !this.canScroll) {
                return;
            }
            const scrollPane = document.getElementById('sport-banner__events');
            if (scrollPane) {
                const scrollPaneLeft = scrollPane.getBoundingClientRect().left;
                const scrollPaneScrollLeft = scrollPane.scrollLeft;
                const scrollPaneWidth = scrollPane.getBoundingClientRect().width;
                const scrollPaneCutoff = scrollPaneScrollLeft - 10;
                let scrollPos = 0;
                Array.from(scrollPane.getElementsByClassName("sport-banner-event")).forEach(child => {
                    let childOffsetLeft = child.getBoundingClientRect().left - scrollPaneLeft + scrollPaneScrollLeft;
                    let childOffsetRight = childOffsetLeft + child.getBoundingClientRect().width;
                    if (childOffsetLeft < scrollPaneCutoff && childOffsetRight > scrollPaneCutoff) {
                        scrollPos = childOffsetRight - scrollPaneWidth;
                    }
                });
                this.smoothScroll(scrollPane, {top: 0, left: scrollPos});
            }
        },
        scrollRight() {
            if (this.scrolling || !this.canScroll) {
                return;
            }
            const scrollPane = document.getElementById('sport-banner__events');
            if (scrollPane) {
                const scrollPaneLeft = scrollPane.getBoundingClientRect().left;
                const scrollPaneScrollLeft = scrollPane.scrollLeft;
                const scrollPaneWidth = scrollPane.getBoundingClientRect().width;
                const scrollPaneBoundry = scrollPaneScrollLeft + scrollPaneWidth;
                const scrollPaneCutoff = scrollPaneBoundry + 10;
                let scrollPos = 0;
                Array.from(scrollPane.getElementsByClassName("sport-banner-event")).forEach(child => {
                    let childOffsetLeft = child.getBoundingClientRect().left - scrollPaneLeft + scrollPaneScrollLeft;
                    let childWidth = child.getBoundingClientRect().width;
                    if (childOffsetLeft < scrollPaneCutoff && childOffsetLeft + childWidth > scrollPaneCutoff) {
                        scrollPos = childOffsetLeft;
                    }
                });
                if (scrollPos) {
                    this.smoothScroll(scrollPane, {top: 0, left: scrollPos});
                }
            }
        },
        smoothScroll(elem: HTMLElement, options: ScrollToOptions) {
            const scrollOptions = Object.assign({behavior: 'smooth'}, options);
            this.scrolling = true;
            elem.scrollTo(scrollOptions);
            this.checkScrollStop(elem)
        },
        checkScrollStop<T>(elem: HTMLElement) {
            return new Promise((resolve) => {
                let same = 0; // a counter
                let lastPos: any = null;
                requestAnimationFrame(check);
                const self = this;
                // this function will be called every painting frame
                // for the duration of the smooth scroll operation
                function check() {
                    // check our current position
                    const newPos = elem.scrollLeft;

                    if (newPos === lastPos) { // same as previous
                        if (same++ > 2) { // if it's more than two frames
                            self.scrolling = false;
                            return resolve(''); // we've come to an halt
                        }
                    } else {
                        same = 0; // reset our counter
                        lastPos = newPos; // remember our current position
                    }
                    // check again next painting frame
                    requestAnimationFrame(check);
                }
            });
        }
    }
});


