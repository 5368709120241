import Vue from 'vue';
import Spinner from 'vue-simple-spinner';
import OverlaySpinner from '@/components/OverlaySpinner.vue';
import axios from 'axios';
import {BadEntityError} from '@/errors/BadEntityError';

export default Vue.extend({
    name: 'LoadingMixing',
    components: {Spinner, OverlaySpinner},
    data() {
        return {
            loading: false,
            saving: false,
            cardDataErrors: {}
        };
    },
    methods: {
        // eslint-disable-next-line no-undef
        async execute(run: () => Promise<any>, defaultMessage: string): Promise<boolean> {
            let success = true;
            try {
                this.loading = true;
                await run();
            } catch (e) {
                if (axios.isCancel(e)) {
                    // Do nothing
                } else {
                    console.log(e);
                }
                success = false;
            }
            this.loading = false;
            return success;
        },

        // eslint-disable-next-line no-undef
        async save(run: () => Promise<any>, defaultMessage: string): Promise<boolean> {
            let success = true;
            try {
                this.saving = true;
                this.cardDataErrors = {};
                await run();
            } catch (e) {
                if (axios.isCancel(e)) {
                    // Do nothing
                } else if (e instanceof BadEntityError) {
                    this.cardDataErrors = e.errors;
                } else {
                    this.$notifyError(e, defaultMessage);
                }
                success = false;
            }
            this.saving = false;
            return success;
        }
    }
});
