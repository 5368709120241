export class PortalError extends Error {
  public displayError?: string;

  constructor(message: string, displayError?: string) {
    super(message);
    if (displayError) {
      this.displayError = displayError;
    }
  }
}

export function getError(err: any, defaultMsg: string = 'An error occurred.'): string {
    if (typeof err == 'string') {
        return err;
    } else if (err instanceof PortalError && err.displayError) {
        return err.displayError;
    }
    console.error(err);
    return defaultMsg;
}
