import Vue from 'vue'
import VueSimpleSpinner from 'vue-simple-spinner';
import 'vue-loaders/dist/vue-loaders.css';
import SportsBannerApp from "@/SportsBannerApp.vue";
import Notifications from "vue-notification";
import NotifyPlugin from "@/plugins/NotifyPlugin";
import {installFilters} from "@/plugins/BekFilters";
import smoothscroll from 'smoothscroll-polyfill';

Vue.config.productionTip = false;
Vue.config.ignoredElements = [/^ion-/];

Vue.use(Notifications);
Vue.use(NotifyPlugin);

// in order to support smooth scrolling on safari.
smoothscroll.polyfill();

Vue.component("vue-simple-spinner", VueSimpleSpinner);

installFilters();
new Vue({
    el: '#bek-public-sports-banner-app',
    render: (h) => h(SportsBannerApp)
}).$mount('#bek-public-sports-banner-app');
