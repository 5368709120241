import Vue, {VueConstructor} from 'vue';
import {getError} from '@/errors/PortalError';
import notify from 'vue-notification';

export default {
    install(vue: VueConstructor<Vue>) {
        vue.mixin({
            methods: {
                $notifyError(err: any, defaultMessage?: string) {
                    this.$notify({
                        title: 'Error',
                        text: getError(err, defaultMessage),
                        type: 'error',
                        duration: 5000
                    });
                },
                $notifySuccess(message: string) {
                    this.$notify({
                        title: 'Success',
                        text: message,
                        type: 'success',
                        duration: 5000
                    });
                }
            }
        });
    }
};
