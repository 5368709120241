import Vue from 'vue';
import { PortalError } from '@/errors/PortalError';
import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelToken} from 'axios';
import {BadEntityError} from '@/errors/BadEntityError';

class HTTPClass {
    private http: AxiosInstance;
    constructor() {
        this.http = axios.create({
            baseURL: process.env.VUE_APP_API
        });

        this.http.interceptors.request.use((config: any) => {
            return config;
        }, (err: any) => Promise.reject(err));
    }

    public get(path: string, cancelToken?: CancelToken): Promise<any> {
        return this.http.get(path, { cancelToken }).then(this.handleResponse).catch(this.handleError);
    }

    public post(path: string, body?: any, cancelToken?: CancelToken): Promise<any> {
        return this.http.post(path, body, { cancelToken }).then(this.handleResponse).catch(this.handleError);
    }

    /** A POST request that assumes the response is a blob representings a file for download. */
    public postExpectingBlob(path: string, body?: any, cancelToken?: CancelToken): Promise<Buffer> {
        return this.http({method: 'post', url: process.env.VUE_APP_API + path, responseType: 'blob', data: body, cancelToken: cancelToken})
            .then(this.handleResponse).catch(this.handleError);
    }

    // `delete` is a reserved keyword so I'm sacrificing consistency to keep the other methods less verbose
    public httpDelete(path: string): Promise<any> {
        return this.http.delete(path).then(this.handleResponse).catch(this.handleError);
    }

    public put(path: string, body?: any, config?: AxiosRequestConfig): Promise<any> {
        return this.http.put(path, body, config).then(this.handleResponse).catch(this.handleError);
    }

    private handleResponse(res: AxiosResponse): any {
        return res.data;
    }

    private handleError(err: AxiosError): Promise<any> {
        // If the caller code cancelled the request, throw an error so they can display a message if necessary.
        // This also requires callers to manually catch errors and suppress cancel errors.
        // However, this will provide a better UX otherwise we would have to return undefined here
        // and expect devs to check the return value which is not what we want.
        if (axios.isCancel(err)) {
            throw err;
        }
        let error = 'An error occurred';
        if (err.response && err.response.status === 401) {
            (Vue as any).notify({
                title: 'Unauthorized',
                text: 'BAD',
                type: 'warn',
            });
            return Promise.resolve();
        } else if (err.response && err.response.status === 403) {
            error = err.response.data.message;
        } else if (err.response && err.response.status === 400) {
            error = err.response.data;
        } else if (err.response && err.response.status === 422) {
            const errors = err.response.data;
            for (const key in errors) {
                if (errors.hasOwnProperty(key) && errors[key].msg) {
                    errors[key] = errors[key].msg;
                }
            }
            throw new BadEntityError(errors);
        } else {
            error = 'An error occurred';
        }
        throw new PortalError(error, error);
    }
}

export const HTTP = new HTTPClass();
